.loading-container {
  display: flex;
  justify-content: space-between;
  width: 100px; /* Adjust as needed */
}

.loading-bar {
  width: 20px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  background-color: #333; /* Adjust color as needed */
  animation: loading 1.2s infinite ease-in-out;
}

.loading-bar:nth-child(1) {
  animation-delay: -0.24s;
}

.loading-bar:nth-child(2) {
  animation-delay: -0.12s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0s;
}

@keyframes loading {
  0%, 20%, 50%, 80%, 100% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(1.2);
  }
  60% {
    transform: scaleY(0.7);
  }
}